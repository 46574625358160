export const navList = [
  { label: "HOME", route: "home" },
  { label: "STORIES", route: "stories-home" },
  { label: "TESTIMONIALS", route: "testimonials" },
  // { label: "STORE", route: "store" },
  { label: "ABOUT", route: "about" },
  { label: "ENQUIRE", route: "enquire" },
  { label: "", route: "stories" },
];

export const navListAdmin = [
  { label: "HOME", route: "add-admin-home" },
  { label: "STORIES", route: "admin-stories" },
  { label: "TESTIMONIALS", route: "admin-testimonials" },
  { label: "ENQUIRE", route: "admin-enquire" },
];
