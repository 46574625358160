import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomCarousel from "../../../components/carousel/Carousel";
import {
  ADMIN_HOME_CROUSEL,
  ALL_STORIES_LIST,
} from "../../../constant/apiEndPoint";
import { getRequest } from "../../../helper/AxiosClient";
import { hashLoader } from "../../../helper/Utils";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

// Custom hook for in-view animation
const useInViewAnimation = (delay = 0) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.1, // Trigger when 10% of the element is in view
  });

  const animationProps = {
    ref,
    initial: { opacity: 0, y: 50 },
    animate: inView ? { opacity: 1, y: 0 } : {},
    transition: { delay, duration: 0.5 },
  };

  return animationProps;
};

// Functional component to wrap children with in-view animation
const AnimatedDiv = ({ children, delay }) => {
  const animationProps = useInViewAnimation(delay);
  return <motion.div {...animationProps}>{children}</motion.div>;
};

const Home = () => {
  const navigate = useNavigate();

  const [BigImages, setBigImages] = useState([]);
  const [smallImages, setSmallImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allStoriesList, setAllStoriesList] = useState([]);
  const [loadedImages, setLoadedImages] = useState({});

  const getBigCarousel = async () => {
    setLoading(true);
    try {
      const res = await getRequest(ADMIN_HOME_CROUSEL + "?type=big");
      setBigImages(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getSmallCarousel = async () => {
    setLoading(true);
    try {
      const res = await getRequest(ADMIN_HOME_CROUSEL + "?type=small");
      setSmallImages(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getStoriesList = async () => {
    setLoading(true);
    try {
      const res = await getRequest(`${ALL_STORIES_LIST}?enable=true`);
      setAllStoriesList(res.data.data.slice(0, 3));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getBigCarousel();
    getSmallCarousel();
    getStoriesList();
  }, []);

  const handleImageLoad = (id) => {
    setLoadedImages((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  return (
    <>
      {loading ? (
        hashLoader()
      ) : (
        <>
          <div className="container mx-auto px-2 w-full sm:w-[65vw] pb-6">
            <CustomCarousel
              images={BigImages}
              autoplaySpeed={2500}
              autoplay={true}
            />
          </div>
          <AnimatedDiv delay={0.1}>
            <div className="">
              <div className="grid grid-cols-1 mt-5 text-center">
                <h1 className="text-xl sm:text-3xl mb-4">
                  "You Feel. I Focus. We Frame"
                </h1>
                <p className="text-xs sm:text-sm mb-4">
                  A wedding is a validation coupled with the showcase of <br />
                  Love inclusive of various events with exotic venues, food,
                  guests, dresses, jewellery, and so on- <br />
                  What if it could never be recorded?
                </p>
                <p className="text-xs sm:text-sm">
                  A chronology of a couple's journey where they vow together to
                  be <strong>One</strong>.
                </p>
                <p className="text-xs sm:text-sm uppercase mt-6">
                  we are creating fiction out of reality.
                </p>
              </div>
              <div className="container mx-auto px-2 w-full sm:w-[85vw] mt-10">
                <CustomCarousel
                  images={smallImages}
                  autoplaySpeed={2500}
                  slidesToShow={3}
                  autoplay={true}
                />
              </div>
            </div>
          </AnimatedDiv>
          <AnimatedDiv delay={0.3}>
            <div className="grid grid-cols-1 my-5 text-center">
              <h1 className="text-xl sm:text-3xl mb-4">REAL LOVE STORIES</h1>
              <h2 className="text-xs sm:text-sm mb-4">
                LIKE A RIVER FLOWS SURELY TO THE SEA, SO IT GOES SOME THINGS ARE
                MEANT TO BE.
              </h2>
            </div>
          </AnimatedDiv>
          <AnimatedDiv delay={0.5}>
            <div className="flex justify-between gap-1 mx-auto px-2 w-full sm:w-[85vw] pb-0 sm:pb-8">
              {allStoriesList.map((cur) => (
                <motion.div
                  key={cur.id}
                  className="w-full max-w-xxl cursor-pointer text-xs"
                  onClick={() => navigate(`/stories-home/stories/stories-details/${cur.id}`)}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 * cur.id, duration: 0.5 }}
                >
                  <img
                    src={cur?.homeCover?.image}
                    onLoad={() => handleImageLoad(cur.id)}
                    className={`object-cover transition-opacity duration-1000 ${
                      loadedImages[cur.id] ? "opacity-100" : "opacity-0"
                    }`}
                    alt="cover"
                  />
                  <div className="pt-1 text-xs sm:text-2xl">
                    {cur.maleName} - {cur.femaleName}
                  </div>
                </motion.div>
              ))}
            </div>
          </AnimatedDiv>
        </>
      )}
    </>
  );
};

export default Home;
