import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import companyLogo from "../../assets/blackTextLogo.png";
import { navList, navListAdmin } from "../../constant/NavbarList";
import { ROUTES } from "../../constant/routes";
import { getCurrentUser } from "../../helper/Utils";

const Navbar = () => {
  const urlPath = useLocation().pathname;
  const location = urlPath.split("/");
  const navigate = useNavigate();

  const token = getCurrentUser()?.token;

  const handleLogOut = () => {
    navigate(ROUTES.LOGIN_ROUTE);
    localStorage.clear();
  };
  
  return (
    <>
      <div className="w-32 h-32 container mx-auto">
        <Link to={ROUTES.HOME}>
          <img
            src={companyLogo}
            alt="logo"
            className="w-full h-full object-contain py-5"
          />
        </Link>
      </div>
      {token ? (
        <div class="font-[300] flex justify-evenly sm:justify-center container mx-auto align-middle mb-5">
          {navListAdmin.map((cur) => {
            return (
              <Link
                to={cur.route}
                className={`text-black-300 hover:text-[#da2337] px-1 sm:px-3 py-1 sm:py-2 text-xs sm:text-[15px] ${
                  location.includes(cur.route) && "text-[#da2337]"
                }`}
              >
                {cur.label}
              </Link>
            );
          })}
          <Link
            className="text-black-300 hover:text-[#da2337] text-xs sm:text-[15px] cursor-pointer mt-2 text-xs ml-2 font-[300]"
            onClick={handleLogOut}
          >
            LOG OUT
          </Link>
        </div>
      ) : (
        <div class="font-[300] flex justify-evenly sm:justify-center container mx-auto mb-5">
          {navList.map((cur) => {
            return (
              <Link
                to={cur.route}
                className={`text-black-300 hover:text-[#da2337] px-1 sm:px-3 py-1 sm:py-2 text-xs sm:text-[15px] ${
                  location.includes(cur.route) && "text-[#da2337]"
                }`}
              >
                {cur.label}
              </Link>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Navbar;
