import axios from "axios";
import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import CustomCarousel from "../../../components/carousel/Carousel";
import {
  ADD_IMAGE_BIG_CROUSEL,
  ADD_IMAGE_SMALL_CROUSEL,
  ADMIN_HOME_CROUSEL,
  ALL_STORIES_LIST,
  API_URL,
  DELETE_BIG_CAROUSEL,
} from "../../../constant/apiEndPoint";
import { deleteRequest, getRequest } from "../../../helper/AxiosClient";
import { getCurrentUser, hashLoader } from "../../../helper/Utils";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";

const AdminHome = () => {
  const [BigImages, setBigImages] = useState([]);
  const [smallImages, setSmallImages] = useState([]);
  const [allStoriesList, setAllStoriesList] = useState([]);
  const maxNumber = 69;
  const [loading, setLoading] = useState(false);
  const imageList = [
    {
      image:
        "https://www.hdwallpapers.in/thumbs/2021/designed_art_black_background_hd_black-t2.jpg",
    },
    {
      image:
        "https://www.hdwallpapers.in/thumbs/2021/designed_art_black_background_hd_black-t2.jpg",
    },
    {
      image:
        "https://www.hdwallpapers.in/thumbs/2021/designed_art_black_background_hd_black-t2.jpg",
    },
    {
      image:
        "https://www.hdwallpapers.in/thumbs/2021/designed_art_black_background_hd_black-t2.jpg",
    },
  ];

  const getBigCarousel = async () => {
    setLoading(true);
    try {
      const res = await getRequest(ADMIN_HOME_CROUSEL + "?type=big");
      setBigImages(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getSmallCarousel = async () => {
    setLoading(true);
    try {
      const res = await getRequest(ADMIN_HOME_CROUSEL + "?type=small");
      setSmallImages(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getStoriesList = async () => {
    setLoading(true);
    try {
      const res = await getRequest(ALL_STORIES_LIST);
      setAllStoriesList(res.data.data.slice(0, 3));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  console.log("allStoriesList", allStoriesList);
  useEffect(() => {
    getBigCarousel();
    getSmallCarousel();
    getStoriesList();
  }, []);

  const addBigCarousel = async (imageList) => {
    setLoading(true);
    try {
      const imageURL = imageList[imageList.length - 1].file;
      const formData = new FormData();
      formData.append("image", imageURL);
      const response = await axios.post(
        `${API_URL}${ADD_IMAGE_BIG_CROUSEL}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: getCurrentUser()?.token,
          },
        }
      );
      successNotification(response.data.message);
      console.log("response", response.data.message);
      getBigCarousel();
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const addSmallCarousel = async (imageList) => {
    setLoading(true);
    try {
      const imageURL = imageList[imageList.length - 1].file;
      const formData = new FormData();
      formData.append("image", imageURL);
      const response = await axios.post(
        `${API_URL}${ADD_IMAGE_SMALL_CROUSEL}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: getCurrentUser()?.token,
          },
        }
      );
      successNotification(response.data.message);
      console.log("response", response.data.message);
      getSmallCarousel();
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const onImageRemoveBig = async (image) => {
    setLoading(true);
    try {
      const res = await deleteRequest(`${DELETE_BIG_CAROUSEL}?id=${image.id}`);
      if (res.type === 1) {
        successNotification(res?.response?.data?.message);
        getBigCarousel();
        setLoading(false);
      }
      if (res.type === 2) {
        errorNotification(res.errormessage);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onImageRemoveSmall = async (image) => {
    setLoading(true);
    try {
      const res = await deleteRequest(`${DELETE_BIG_CAROUSEL}?id=${image.id}`);
      if (res.type === 1) {
        successNotification(res?.response?.data?.message);
        getSmallCarousel();
        setLoading(false);
      }
      if (res.type === 2) {
        errorNotification(res.errormessage);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        hashLoader()
      ) : (
        <>
          <div className="container mx-auto px-2 w-full sm:w-[70vw] pb-10">
            <CustomCarousel
              images={BigImages.length !== 0 ? BigImages : imageList}
              autoplaySpeed={2500}
              slidesToShow={1}
              autoplay={true}
            />
            <ImageUploading
              multiple
              value={BigImages}
              onChange={(imageList) => addBigCarousel(imageList)}
              maxNumber={maxNumber}
              dataURLKey="image"
            >
              {({ imageList, onImageUpload }) => (
                <div class="flex overflow-auto mt-5">
                  <div class="flex flex-nowrap">
                    <button
                      onClick={onImageUpload}
                      className="w-40 h-40 border-2 border-red-700"
                    >
                      Click
                    </button>
                    {imageList.map((image, index) => {
                      return (
                        <div class="inline-block px-3 relative">
                          <span
                            className="absolute p-2 rounded-lg text-transparent hover:text-black bg-transparent hover:bg-gray-50 opacity-50 cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                            onClick={() => onImageRemoveBig(image)}
                          >
                            Remove
                          </span>
                          <div class="max-w-40 w-40 max-w-xs overflow-hidden">
                            <img
                              src={image["image"]}
                              alt=""
                              className="object-cover w-40 h-40"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </ImageUploading>
          </div>
          <div className="container mx-auto px-2 w-full sm:w-[90vw] pb-10">
            <CustomCarousel
              images={smallImages.length !== 0 ? smallImages : imageList}
              autoplaySpeed={2500}
              slidesToShow={3}
            />
            <ImageUploading
              multiple
              value={smallImages}
              onChange={(imageList) => addSmallCarousel(imageList)}
              maxNumber={maxNumber}
              dataURLKey="image"
            >
              {({ imageList, onImageUpload }) => (
                <div class="flex overflow-auto mt-5">
                  <div class="flex flex-nowrap">
                    <button
                      onClick={onImageUpload}
                      className="w-40 h-40 border-2 border-red-700"
                    >
                      Click
                    </button>
                    {imageList.map((image) => {
                      return (
                        <div class="inline-block px-3 relative">
                          <span
                            className="absolute p-2 rounded-lg text-transparent hover:text-black bg-transparent hover:bg-gray-50 opacity-50 cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                            onClick={() => onImageRemoveSmall(image)}
                          >
                            Remove
                          </span>
                          <div class="max-w-40 w-40 max-w-xs overflow-hidden">
                            <img
                              src={image["image"]}
                              alt=""
                              className="object-cover w-40 h-40"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </ImageUploading>
          </div>
        </>
      )}
    </>
  );
};

export default AdminHome;
