// export const API_URL = "http://localhost:8000/api/v1/";
export const API_URL = "https://api.sadhanaphotoimage.in/api/v1/";

export const LOGIN_EP = "admin/login";
export const ADMIN_HOME_CROUSEL = "homeCarousel/getImage";
export const ADD_IMAGE_BIG_CROUSEL = "homeCarousel/addImage?type=big";
export const ADD_IMAGE_SMALL_CROUSEL = "homeCarousel/addImage?type=small";
export const ADD_IMAGE_TESTIMONIAL = "homeCarousel/addImage?type=testimonial";
export const DELETE_BIG_CAROUSEL = "homeCarousel/removeImage";
export const ALL_STORIES_LIST = "story/getStory";
export const ADD_STORY = "story/registerStory";
export const UPDATE_STORIES = "story/update";
export const ADD_STORES_STORIES = "story/addMedia";
export const DELETE_STORIES_IMAGE = "story/deleteMedia";
export const DELETE_STORIES = "story/delete";
export const FEEDBACK_FORM = "feedback/create";
export const GET_FEEDBACK_FORM = "feedback/get";
export const CREATED_ORDER_ID = "payment/createOrderID";
