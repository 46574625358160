import React, { useState } from "react";
import { postRequest } from "../../../helper/AxiosClient";
import { LOGIN_EP } from "../../../constant/apiEndPoint";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constant/routes";
import { setLocalStorageItem } from "../../../helper/localStorage";
import whiteLogo from "../../../assets/whiteTextLogo.png";

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    userName: "admin@123",
    password: "Test@123",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await postRequest(LOGIN_EP, formData);
      console.log(res, "resresresresres");
      if (res.type === 1) {
        setLocalStorageItem(
          "current_user",
          JSON.stringify(res?.response?.data?.data)
        );
        successNotification(res?.response?.data?.message);
        navigate(ROUTES.ADMIN_HOME);
      }
      if (res.type === 2) {
        errorNotification(res.errormessage);
      }
    } catch (error) {
      console.log("res====>", error);
    }
  };

  return (
    <section className="min-h-screen flex items-stretch text-white bg-black">
      <div className="lg:flex w-1/2 hidden  h-[100vh] justify-center align-middle">
        <img src={whiteLogo} alt="" />
      </div>
      <div className="lg:w-1/2 w-full flex items-center justify-center text-center md:px-16 px-0 z-0 bg-black">
        <div className="w-full py-4" style={{ backgroundColor: "#161616" }}>
          <form
            onSubmit={handleSubmit}
            className="sm:w-2/3 w-full px-4 lg:px-0 mx-auto"
          >
            <div className="pb-2 pt-4">
              <input
                name="userName"
                id="userName"
                placeholder="userName"
                value={formData.userName}
                onChange={handleInputChange}
                className="block w-full p-4 text-lg rounded-sm bg-black"
              />
            </div>
            <div className="pb-2 pt-4">
              <input
                type="password"
                name="password"
                id="password"
                placeholder="password"
                value={formData.password}
                onChange={handleInputChange}
                className="block w-full p-4 text-lg rounded-sm bg-black"
              />
            </div>
            <div className="px-4 pb-2 pt-4">
              <button
                type="submit"
                className="uppercase block w-full p-2 text-lg rounded-full bg-white text-black focus:outline-none"
              >
                sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
