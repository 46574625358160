import React from "react";
import { motion } from "framer-motion";
import aboutImg from "../../../assets/about.jpg";
import { useInView } from "react-intersection-observer";

// Custom hook for in-view animation
const useInViewAnimation = (delay = 0) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.1, // Trigger when 10% of the element is in view
  });

  const animationProps = {
    ref,
    initial: { opacity: 0, y: 50 },
    animate: inView ? { opacity: 1, y: 0 } : {},
    transition: { delay, duration: 0.5 },
  };

  return animationProps;
};

// Functional component to wrap children with in-view animation
const AnimatedDiv = ({ children, delay }) => {
  const animationProps = useInViewAnimation(delay);
  return <motion.div {...animationProps}>{children}</motion.div>;
};

const About = () => {
  return (
    <div className="container mx-auto px-2 w-full sm:w-[70vw]">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <AnimatedDiv delay={0.1}>
          <img
            className="w-full object-cover"
            src={aboutImg}
            alt="Modern building architecture"
          />
        </AnimatedDiv>
        <AnimatedDiv delay={0.3}>
          <div className="text-center">
            <h1 className="text-xl md:text-3xl mb-4">SADHANA.</h1>
            <p className="mb-4">
              <strong className="mb-4">Moment. Memory. Miracle.</strong>
            </p>
            <p className="mb-4 text-sm md:text-xl">
              The journey is never about a shot but the unsaid <br /> stories
              within the shooting experience. The quest <br /> within is of
              something which is ‘epiphanic’.
            </p>
            <p className="mb-4 text-sm md:text-xl">
              As an artist whose medium of bringing the art to the <br /> world
              is an intent of compartmentalising the moments, <br /> I am all
              about things which are not seen by the naked <br /> eye but a soul
              which feels what cannot be said <br /> through any other medium.
            </p>
            <p className="mb-4 text-sm md:text-xl">
              The world is material but I am still looking for a <br /> metaphor
              in it. Anything which can allow me the <br /> space to create what
              is not felt is the domain I am <br /> looking for.
            </p>
            <p className="text-sm md:text-xl">
              <strong>People. Photographs. Perfection.</strong>
            </p>
          </div>
        </AnimatedDiv>
      </div>
    </div>
  );
};

export default About;
