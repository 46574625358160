import React, { useEffect, useState } from "react";
import { getRequest } from "../../../helper/AxiosClient";
import { GET_FEEDBACK_FORM } from "../../../constant/apiEndPoint";
import { hashLoader } from "../../../helper/Utils";

const AdminEnquire = () => {
  const [loading, setLoading] = useState(false);
  const [enquireList, setEnquireList] = useState([]);

  const getEnquireList = async () => {
    setLoading(true);
    try {
      const res = await getRequest(GET_FEEDBACK_FORM);
      setEnquireList(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getEnquireList();
  }, []);

  const getTextColor = (flag) => {
    switch (flag) {
      case 0:
        return "text-green-700";
      case 1:
        return "text-yellow-700";
      case 2:
        return "text-blue-700";
      default:
        return "text-black";
    }
  };

  return (
    <>
      <div className="flex justify-center px-8 mt-10">
        {loading ? (
          hashLoader()
        ) : enquireList.length === 0 ? (
          <div
            className="text-[25px] h-[70vh] flex"
            style={{ alignItems: "center" }}
          >
            No Record Found
          </div>
        ) : (
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="border border-gray-300 py-2">No.</th>
                <th className="border border-gray-300 py-2">First Name</th>
                <th className="border border-gray-300 py-2">Last Name</th>
                <th className="border border-gray-300 py-2">Email</th>
                <th className="border border-gray-300 py-2">Subject</th>
                <th className="border border-gray-300 py-2">Details</th>
              </tr>
            </thead>
            <tbody>
              {enquireList.map((enquiry, index) => (
                <tr key={enquiry.id}>
                  <td className="border border-gray-300 px-4 py-2">
                    {index + 1}
                  </td>
                  <td
                    className={`border border-gray-300 px-4 py-2 ${getTextColor(
                      enquiry.flag
                    )}`}
                  >
                    {enquiry.firstname}
                  </td>
                  <td
                    className={`border border-gray-300 px-4 py-2 ${getTextColor(
                      enquiry.flag
                    )}`}
                  >
                    {enquiry.lastname}
                  </td>
                  <td
                    className={`border border-gray-300 px-4 py-2 ${getTextColor(
                      enquiry.flag
                    )}`}
                  >
                    {enquiry.email}
                  </td>
                  <td
                    className={`border border-gray-300 px-4 py-2 ${getTextColor(
                      enquiry.flag
                    )}`}
                  >
                    {enquiry.subject}
                  </td>
                  <td
                    className={`border border-gray-300 px-4 py-2 ${getTextColor(
                      enquiry.flag
                    )}`}
                  >
                    {enquiry.details}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {enquireList.length > 0 && (
        <div className=" mt-5 mx-8 border-2 border-gray-500 flex float-right p-3">
          <div>
            <div className="flex">
              <span className="h-[10px] w-[10px] bg-green-700 mt-1.5 mr-2" />
              <span className="text-green-700">Today Enquire</span>
            </div>
            <div className="flex">
              <span className="h-[10px] w-[10px] bg-yellow-700 mt-1.5 mr-2" />
              <span className="text-yellow-700">Weekly Enquire</span>
            </div>
            <div className="flex">
              <span className="h-[10px] w-[10px] bg-blue-700 mt-1.5 mr-2" />
              <span className="text-blue-700">Monthly Enqurie</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminEnquire;
