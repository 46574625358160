import React from "react";
import companyLogo from "../../assets/blackTextLogo.png";
import email from "../../assets/gmail.png";
import phone from "../../assets/phone.png";
import { HiOutlineMail } from "react-icons/hi";
import { FaInstagram } from "react-icons/fa6";
import { MdOutlinePhone } from "react-icons/md";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constant/routes";

const Footer = () => {
  return (
    <footer className="bg-[#f5f1eb] py-2">
      <div className="container mx-auto text-center">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
          <div className="flex-1 text-center hidden md:block">
            <a className="text-gray-700 hover:text-gray-900">Contact Us</a>
          </div>
          <div className="flex-1 flex justify-center">
            <Link to={ROUTES.HOME}>
              <img
                src={companyLogo}
                alt="Company Logo"
                className="w-28 sm:w-40"
              />
            </Link>
          </div>

          <div className="flex-1 text-center">
            <p className="text-gray-700 mb-2">
              <a
                href="tel:+916351242466"
                className="inline-flex items-center space-x-2 hover:text-gray-900"
              >
                <MdOutlinePhone />
                <span className="text-sm md:text-lg">+91 6351242466</span>
              </a>
            </p>
            <div
              className="w-full justify-center mb-3 flex cursor-pointer"
              style={{ display: "flex", alignItems: "center" }}
            >
              <a
                href="https://www.instagram.com/sadhana_photo_image"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.instagram.com/sadhana_photo_image"
                target="_blank"
                rel="noopener noreferrer"
                className="ms-2 text-sm md:text-lg"
              >
                sadhana_photo_image
              </a>
            </div>
            <p className="text-gray-700 flex items-center text-center justify-center">
              <a
                href="mailto:sadhnaphotoimage@gmail.com"
                className="hover:text-gray-900 flex items-center"
              >
                <HiOutlineMail />
                <span className="ml-2 text-sm md:text-lg">
                  sadhnaphotoimage@gmail.com
                </span>
              </a>
            </p>
          </div>
        </div>

        <div className="container mx-auto text-center px-0 xl:px-40">
          <hr className="my-6 border-gray-300" />
          <div className="flex flex-col md:flex-row justify-between items-center text-gray-600">
            <p className="text-xs sm:text-sm">
              &copy; 2024, SADHANA PHOTO IMAGE, ALL RIGHTS RESERVED.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
