import React from "react";
import { motion } from "framer-motion";
import { useFormik } from "formik";
import * as Yup from "yup";
import enquire from "../../../../src/assets/enquire.jpg";
import { postRequest } from "../../../helper/AxiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import { FEEDBACK_FORM } from "../../../constant/apiEndPoint";

// Animation variants
const variants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const Enquire = () => {
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      subject: "",
      details: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("First Name is required"),
      lastname: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      subject: Yup.string()
        .required("Subject is required")
        .min(4, "Subject must be at least 4 characters long"),
      details: Yup.string().required("Details are required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const res = await postRequest(FEEDBACK_FORM, values);
        if (res.type === 1) {
          successNotification(res?.response?.data?.message);
        } else if (res.type === 2) {
          errorNotification(res.errormessage);
        }
        resetForm();
      } catch (error) {
        console.log("res====>", error);
      }
    },
  });

  return (
    <div className="container mx-auto px-2 w-full sm:w-[50vw]">
      <motion.img
        src={enquire}
        className="w-full object-cover"
        alt=""
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={{ duration: 0.8 }}
      />
      <motion.p
        className="text-center my-5 text-sm md:text-lg"
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <a
          href="mailto:sadhnaphotoimage@gmail.com"
          className="hover:text-gray-900"
        >
          <strong> email</strong> : sadhnaphotoimage@gmail.com
        </a>
        <br />
        <br />
        You can draft an email to us on the above mentioned address, <br />
        or can send us the details by filling the form below.
        <br />
        <br />
        Thank you!
      </motion.p>
      <div className="container w-full md:w-[50vw] mx-auto">
        <form className="pb-0 sm:pb-10" onSubmit={formik.handleSubmit}>
          <motion.div
            className="grid gap-2 md:gap-6 mb-6 md:grid-cols-2"
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <motion.div variants={variants}>
              <label htmlFor="firstname" className="text-sm md:text-lg mb-2">
                First Name<span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="firstname"
                className="w-full p-3 text-xs border border-gray-300"
                placeholder="Hari"
                {...formik.getFieldProps("firstname")}
              />
              {formik.touched.firstname && formik.errors.firstname ? (
                <div className="text-red-500 text-xs mt-1">
                  {formik.errors.firstname}
                </div>
              ) : null}
            </motion.div>
            <motion.div variants={variants}>
              <label htmlFor="lastname" className="text-sm md:text-lg mb-2">
                Last Name<span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="lastname"
                className="w-full p-3 text-xs border border-gray-300"
                placeholder="Patel"
                {...formik.getFieldProps("lastname")}
              />
              {formik.touched.lastname && formik.errors.lastname ? (
                <div className="text-red-500 text-xs mt-1">
                  {formik.errors.lastname}
                </div>
              ) : null}
            </motion.div>
          </motion.div>
          <motion.div
            className="mb-6"
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            <label htmlFor="email" className="text-sm md:text-lg mb-2">
              Email address<span className="text-red-400">*</span>
            </label>
            <input
              type="email"
              id="email"
              className="w-full p-3 text-xs border border-gray-300"
              placeholder="hari.patel@xyz.com"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-xs mt-1">
                {formik.errors.email}
              </div>
            ) : null}
          </motion.div>
          <motion.div
            className="mb-6"
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ duration: 0.8, delay: 0.8 }}
          >
            <label htmlFor="subject" className="text-sm md:text-lg mb-2">
              Subject<span className="text-red-400">*</span>
            </label>
            <input
              type="text"
              id="subject"
              className="w-full p-3 text-xs border border-gray-300"
              placeholder="Subject"
              {...formik.getFieldProps("subject")}
            />
            {formik.touched.subject && formik.errors.subject ? (
              <div className="text-red-500 text-xs mt-1">
                {formik.errors.subject}
              </div>
            ) : null}
          </motion.div>
          <motion.div
            className="mb-6"
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ duration: 0.8, delay: 1 }}
          >
            <label htmlFor="details" className="text-sm md:text-lg mb-2">
              Event Dates / Details<span className="text-red-400">*</span>
            </label>
            <textarea
              id="details"
              rows="4"
              className="w-full p-3 text-xs border border-gray-300"
              placeholder="Write your thoughts here..."
              {...formik.getFieldProps("details")}
            ></textarea>
            {formik.touched.details && formik.errors.details ? (
              <div className="text-red-500 text-xs">
                {formik.errors.details}
              </div>
            ) : null}
          </motion.div>
          <motion.div
            className="w-full flex justify-center"
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ duration: 0.8, delay: 1.2 }}
          >
            <button
              type="submit"
              className="text-white bg-black p-3 min-w-[120px]"
            >
              SUBMIT
            </button>
          </motion.div>
        </form>
      </div>
    </div>
  );
};

export default Enquire;
