export const ROUTES = {
  LOGIN_ROUTE: "/login",
  UNAUTHORIZE_ROUTE: "unAuthorize",
  PAGE_NOT_FOUND_ROUTE: "404",

  HOME: "home",
  STORIES: "stories",
  STORIES_DETAILS: "stories-home/stories/stories-details/:id",
  TESIMONIALS: "testimonials",
  STORE: "store",
  ABOUT: "about",
  ENQURIE: "enquire",
  BUY: "buy",
  STORIES_HOME: "stories-home",

  DETAILS_STORE: "/store/clin-details",
  ADMIN_HOME: "/add-admin-home",
  ADMIN_STORIES: "/admin-stories",
  ADD_ADMIN_STORIES: "/admin-stories/add-admin-stories",
  EDIT_ADMIN_STORIES: "/admin-stories/edit-stories/:id",
  TESTIMONIAL: "/admin-testimonials",
  ADMIN_ENQURIRE: "/admin-enquire",
};
