import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { useNavigate, useParams } from "react-router-dom";
import {
  ADD_STORES_STORIES,
  ADD_STORY,
  ALL_STORIES_LIST,
  API_URL,
  DELETE_STORIES_IMAGE,
  UPDATE_STORIES,
} from "../../../constant/apiEndPoint";
import { ROUTES } from "../../../constant/routes";
import { deleteRequest, getRequest } from "../../../helper/AxiosClient";
import { getCurrentUser, hashLoader } from "../../../helper/Utils";
import * as Yup from "yup";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import YouTube from "react-youtube";

function AddStories() {
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState();
  const [imagePreviewHome, setImagePreviewHome] = useState(null);
  const [imageFileHome, setImageFileHome] = useState();
  const [loading, setLoading] = useState(false);
  const [byIdStoryDetails, setByIdStoryDetails] = useState([]);
  const [BigImages, setBigImages] = useState([]);
  const navigate = useNavigate();
  const maxNumber = 69;
  const { id } = useParams();

  const getStoriesList = async () => {
    setLoading(true);
    try {
      const res = await getRequest(`${ALL_STORIES_LIST}?id=${id}`);
      console.log(res, "resresresresres");
      setByIdStoryDetails(res.data.data);
      setBigImages(res.data.data.media);
      setImagePreview(res?.data?.data?.coverPhoto?.image);
      setImagePreviewHome(res?.data?.data?.homeCover?.image);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      getStoriesList();
    }
    // eslint-disable-next-line
  }, []);

  const handleImagePreview = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImagePreviewHome = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFileHome(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewHome(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const submitAddStoriesDetailsApiCall = async (value) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("maleName", value.maleName);
      formData.append("femaleName", value.femaleName);
      formData.append("weddingDate", value.weddingDate);
      formData.append("destination", value.destination);
      formData.append("description", value.description);
      formData.append("photographers", value.photographers);
      formData.append("videographers", value.videographers);
      formData.append("locations", value.locations);
      formData.append("makeupBy", value.makeupBy);
      formData.append("outfitBy", value.outfitBy);
      formData.append("eventOrganiser", value.eventOrganiser);
      formData.append("djBy", value.djBy);
      formData.append("mehendiBy", value.mehendiBy);
      formData.append("enable", value.enable);
      formData.append("type", value.type);
      imageFile && formData.append("coverPhoto", imageFile);
      imageFileHome && formData.append("homeCover", imageFileHome);
      if (!id) {
        const response = await axios.post(`${API_URL}${ADD_STORY}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: getCurrentUser()?.token,
          },
        });
        successNotification(response.data.message);
        setImageFile();
        setImagePreview();
        navigate(ROUTES.ADMIN_STORIES);
        setLoading(false);
      } else {
        const response = await axios.patch(
          `${API_URL}${UPDATE_STORIES}?id=${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: getCurrentUser()?.token,
            },
          }
        );
        successNotification(response?.data?.message);
        navigate(ROUTES.ADMIN_STORIES);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const addBigCarousel = async (imageList) => {
    setLoading(true);
    try {
      if (id) {
        const imageURL = imageList[imageList.length - 1].file;
        const formData = new FormData();
        formData.append("image", imageURL);
        const response = await axios.post(
          `${API_URL}${ADD_STORES_STORIES}?id=${id}&type=MEDIA_IMAGE`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: getCurrentUser()?.token,
            },
          }
        );
        successNotification(response.data.message);
        getStoriesList();
        console.log("response", response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const onImageRemoveBig = async (image) => {
    setLoading(true);
    try {
      const res = await deleteRequest(`${DELETE_STORIES_IMAGE}?id=${image.id}`);
      if (res.type === 1) {
        successNotification(res?.response?.data?.message);
        getStoriesList();
        setLoading(false);
      }
      if (res.type === 2) {
        errorNotification(res.errormessage);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    maleName: Yup.string().required("Male name is required"),
    femaleName: Yup.string().required("Female name is required"),
    weddingDate: Yup.date().required("Wedding date is required"),
    destination: Yup.string().required("Destination is required"),
    description: Yup.string().required("Description is required"),
  });

  const [showModal, setShowModal] = useState(false);
  const [videoLink, setVideoLink] = useState("");

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setVideoLink(""); // Reset video link when closing modal
  };

  const getYouTubeVideoId = (url) => {
    const regex =
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const matches = url.match(regex);
    return matches ? matches[1] : null;
  };

  const handleVideoSubmit = async (e) => {
    e.preventDefault();
    const videoId = getYouTubeVideoId(videoLink);
    if (!videoId) {
      errorNotification("Invalid YouTube URL");
      return;
    }
    setLoading(true);
    try {
      if (id) {
        const formData = new FormData();
        formData.append("video", videoLink);
        const response = await axios.post(
          `${API_URL}${ADD_STORES_STORIES}?id=${id}&type=VIDEO`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: getCurrentUser()?.token,
            },
          }
        );
        successNotification(response.data.message);
        getStoriesList();
        setVideoLink("");
      }
    } catch (error) {
      errorNotification(
        "Error: " + error.response?.data?.message || error.message
      );
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  const opts = {
    height: "160",
    width: "160",
    playerVars: {
      autoplay: 0,
    },
  };
  const opts1 = {
    height: "490",
    width: "490",
    playerVars: {
      autoplay: 0,
    },
  };
  return (
    <>
      <section className="py-1 bg-blueGray-50">
        {loading ? (
          hashLoader()
        ) : (
          <div className="w-full lg:w-10/12 px-4 mx-auto mt-6">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
              <div className="rounded-t bg-white mb-0 px-6 py-6">
                <div className="text-center flex justify-between">
                  <h6 className="text-blueGray-700 text-xl font-bold">
                    ADD NEW STORIES
                  </h6>
                </div>
              </div>
              <div className="flex-auto px-4 lg:px-10 pt-0">
                <Formik
                  validationSchema={validationSchema}
                  initialValues={{
                    maleName: byIdStoryDetails.maleName || "",
                    femaleName: byIdStoryDetails.femaleName || "",
                    weddingDate: byIdStoryDetails.weddingDate
                      ? byIdStoryDetails.weddingDate.split("T")[0]
                      : "",
                    destination: byIdStoryDetails.destination || "",
                    description: byIdStoryDetails.description || "",
                    enable: byIdStoryDetails.enable || true,
                    photographers: byIdStoryDetails.photographers || "",
                    videographers: byIdStoryDetails.videographers || "",
                    locations: byIdStoryDetails.locations || "",
                    makeupBy: byIdStoryDetails.makeupBy || "",
                    outfitBy: byIdStoryDetails.outfitBy || "",
                    eventOrganiser: byIdStoryDetails.eventOrganiser || "",
                    djBy: byIdStoryDetails.djBy || "",
                    mehendiBy: byIdStoryDetails.mehendiBy || "",
                    homeCover: byIdStoryDetails.homeCover || "",
                    type: byIdStoryDetails.type || "WEDDING",
                  }}
                  onSubmit={(values, actions) => {
                    submitAddStoriesDetailsApiCall(values);
                    actions.setSubmitting(false);
                  }}
                >
                  <Form>
                    <div className="flex flex-wrap py-4">
                      <div className="w-full flex justify-end">
                        <label className="relative inline-flex items-center me-5 cursor-pointer">
                          <Field
                            type="checkbox"
                            name="enable"
                            className="sr-only peer"
                          />
                          <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 dark:peer-focus:ring-orange-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#da2337]"></div>
                          <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Dispaly this story
                          </span>
                        </label>
                      </div>
                      <div className="w-full px-4">
                        {imagePreview && (
                          <img
                            src={imagePreview}
                            alt="Cover Preview"
                            className="mt-2 w-full rounded border-2 object-cover"
                          />
                        )}
                        <div className="relative w-full mb-3 mt-2">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            <label className="text-[#da2337]">* </label>Cover
                            Image
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            name="coverImage"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            onChange={handleImagePreview}
                          />
                        </div>
                      </div>
                      <div className="w-full px-4">
                        {imagePreviewHome && (
                          <img
                            src={imagePreviewHome}
                            alt="home Cover Preview"
                            className="mt-2 w-full rounded border-2 object-cover"
                          />
                        )}
                        <div className="relative w-full mb-3 mt-2">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            <label className="text-[#da2337]">* </label>Home
                            Cover Image
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            name="homeCover"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            onChange={handleImagePreviewHome}
                          />
                        </div>
                        <div className="relative w-full mb-3 mt-2">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            <label className="text-[#da2337]">* </label>
                            Description
                          </label>
                          <Field
                            placeholder="Enter Description"
                            type="text"
                            name="description"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            <label className="text-[#da2337]">* </label>Male
                            Name
                          </label>
                          <Field
                            placeholder="Enter Male Name"
                            type="text"
                            name="maleName"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                          <ErrorMessage
                            name="maleName"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            <label className="text-[#da2337]">* </label>Female
                            Name
                          </label>
                          <Field
                            placeholder="Enter Female Name"
                            type="text"
                            name="femaleName"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                          <ErrorMessage
                            name="femaleName"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 mb-4 px-4">
                        <div className="relative w-full">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            <label className="text-[#da2337]">* </label>Wedding
                            Date
                          </label>
                          <Field
                            type="date"
                            name="weddingDate"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>
                        <ErrorMessage
                          name="weddingDate"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            <label className="text-[#da2337]">* </label>
                            Destination
                          </label>
                          <Field
                            placeholder="Enter Destination"
                            type="text"
                            name="destination"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Photographers
                          </label>
                          <Field
                            placeholder="Enter Photo graphers"
                            type="text"
                            name="photographers"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            videographers
                          </label>
                          <Field
                            placeholder="Enter video graphers"
                            type="text"
                            name="videographers"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Locations
                          </label>
                          <Field
                            placeholder="Enter locations"
                            type="text"
                            name="locations"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Makeup By
                          </label>
                          <Field
                            placeholder="Enter makeup By"
                            type="text"
                            name="makeupBy"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Outfit By
                          </label>
                          <Field
                            placeholder="Enter outfit by"
                            type="text"
                            name="outfitBy"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Event Organiser
                          </label>
                          <Field
                            placeholder="Enter event organiser"
                            type="text"
                            name="eventOrganiser"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Dj By
                          </label>
                          <Field
                            placeholder="Enter DJ By"
                            type="text"
                            name="djBy"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            <span className="text-[#da2337]">* </span>Type
                          </label>
                          <div className="flex items-center space-x-4">
                            <label>
                              <Field type="radio" name="type" value="WEDDING" />
                              <span className="ml-2">Wedding</span>
                            </label>
                            <label>
                              <Field type="radio" name="type" value="PRE-WEDDING" />
                              <span className="ml-2">Pre-wedding</span>
                            </label>
                          </div>
                          <ErrorMessage
                            name="type"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:12 px-4">
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Mehendi By
                          </label>
                          <Field
                            placeholder="Enter mehendi by"
                            type="text"
                            name="mehendiBy"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="bg-[#da2337] mx-4 mt-2 text-white active:bg-orange-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2  mb-1 ease-linear transition-all duration-150"
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
              {id && (
                <div className="px-5 pb-4">
                  <ImageUploading
                    multiple
                    value={BigImages}
                    onChange={(imageList) => addBigCarousel(imageList)}
                    maxNumber={maxNumber}
                    dataURLKey="image"
                  >
                    {({ imageList, onImageUpload }) => (
                      <>
                        <div className="flex mt-5 space-x-5">
                          <button
                            onClick={onImageUpload}
                            className="w-full h-16 border-2 border-red-700"
                          >
                            Upload Image
                          </button>
                          <button
                            onClick={openModal}
                            className="w-full h-16 border-2 border-red-700"
                          >
                            upload video
                          </button>
                        </div>
                        <div className="flex overflow-auto mt-5 space-x-3">
                          {imageList.map((item, index) => (
                            <div key={index} className="relative">
                              <span
                                className="absolute p-2 rounded-lg text-transparent hover:text-black bg-transparent hover:bg-gray-50 opacity-50 cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                onClick={() => onImageRemoveBig(item)}
                              >
                                Remove
                              </span>
                              <div className="w-40 h-40 overflow-hidden">
                                {item.type === "VIDEO" ? (
                                  <YouTube
                                    videoId={getYouTubeVideoId(item.path)}
                                    opts={opts}
                                  />
                                ) : (
                                  <img
                                    src={item.path}
                                    alt=""
                                    className="object-cover w-40 h-40"
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </ImageUploading>
                </div>
              )}
              {BigImages?.map((cur, index) => (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={cur.image}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
      <div className="relative">
        {showModal && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white max-w-lg w-full mx-auto rounded-lg shadow-lg p-2.5">
              <form onSubmit={handleVideoSubmit} className="mb-4">
                <input
                  type="text"
                  value={videoLink}
                  onChange={(e) => setVideoLink(e.target.value)}
                  placeholder="Paste YouTube video link..."
                  className="w-full px-2.5 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                />
                <button
                  type="submit"
                  disabled={loading}
                  className="bg-[#da2337] w-full mt-2 text-white justify-center flex active:bg-orange-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                >
                  {loading ? "Adding..." : "Add Video"}
                </button>
                {videoLink && (
                  <div className="flex justify-center mt-4">
                    <YouTube
                      videoId={getYouTubeVideoId(videoLink)}
                      opts={opts1}
                    />
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AddStories;
