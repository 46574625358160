import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ALL_STORIES_LIST } from "../../../constant/apiEndPoint";
import { getRequest } from "../../../helper/AxiosClient";
import { hashLoader } from "../../../helper/Utils";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

// Custom hook for in-view animation
const useInViewAnimation = (delay = 0) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.1, // Trigger when 10% of the element is in view
  });

  const animationProps = {
    ref,
    initial: { opacity: 0, y: 50 },
    animate: inView ? { opacity: 1, y: 0 } : {},
    transition: { delay, duration: 0.5 },
  };

  return animationProps;
};

// Functional component to wrap children with in-view animation
const AnimatedDiv = ({ children, delay }) => {
  const animationProps = useInViewAnimation(delay);
  return <motion.div {...animationProps}>{children}</motion.div>;
};

const Stories = () => {
  const [allStoriesList, setAllStoriesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const category = queryParams?.get('category');

  const navigate = useNavigate();

  const getStoriesList = async () => {
    setLoading(true);
    try {
      const res = await getRequest(`${ALL_STORIES_LIST}?enable=true&type=${category?.toLocaleUpperCase()}`);
      setAllStoriesList(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoriesList();
  }, [category]); // Added dependency on category

  const handleImageLoad = (id) => {
    setLoadedImages((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  return (
    <>
      {loading ? (
        hashLoader()
      ) : (
        <div className="container mx-auto w-full sm:w-[80vw] px-2">
          {allStoriesList.length === 0 ? (
            <div className="text-centet text-lg font-semibold text-gray-600 flex" style={{height:'49vh',justifyContent:'center',alignItems:'center'}}>
              No stories found.
            </div>
          ) : (
            allStoriesList.map((cur, index) => (
              <AnimatedDiv key={cur.id} delay={index * 0.1}>
                <div
                  className="relative container mx-auto cursor-pointer pb-4 sm:pb-5"
                  onClick={() => navigate(`stories-details/${cur.id}`)}
                >
                  <img
                    className={`object-cover w-full transition-opacity duration-1000 ${
                      loadedImages[cur.id] ? "opacity-100" : "opacity-0"
                    }`}
                    src={cur.coverPhoto?.image}
                    alt="cover"
                    onLoad={() => handleImageLoad(cur.id)}
                  />
                  <figcaption className="mt-2 sm:mt-3 text-sm sm:text-2xl font-[200]">
                    {cur.maleName} - {cur.femaleName}
                  </figcaption>
                  <figcaption className="text-xs font-[200] text-gray-500">
                    {moment(cur.weddingDate).format("MMMM Do YYYY")}
                  </figcaption>
                </div>
              </AnimatedDiv>
            ))
          )}
        </div>
      )}
    </>
  );
};

export default Stories;
