import { Navigate, Outlet } from "react-router-dom";
import { getCurrentUser } from "./Utils";
import Unauthorized from "./Unauthorized";
import { ROUTES } from "../constant/routes";

const RequireAuth = ({ allowedRoles }) => {
  const token = getCurrentUser()?.token;
  const role = getCurrentUser()?.role;
  const permission = [role];

  return token ? (
    permission?.find((role) => allowedRoles?.includes(role)) ? (
      <Outlet />
    ) : (
      <Unauthorized />
    )
  ) : (
    <Navigate to={ROUTES.LOGIN_ROUTE} replace />
  );
};
export default RequireAuth;
