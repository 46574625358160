import React from "react";
import Slider from "react-slick";
import left from "../../assets/left.png";
import right from "../../assets/right.png";

const CustomPrevArrow = (props) => {
  return (
    <div
      className="slick-arrow custom-prev-arrow cursor-pointer"
      onClick={props.onClick}
    >
      <img src={left} alt="" className="w-7" />
    </div>
  );
};

const CustomNextArrow = (props) => {
  return (
    <div
      className="slick-arrow custom-next-arrow cursor-pointer"
      onClick={props.onClick}
    >
      <img src={right} alt="" className="w-7" />
    </div>
  );
};

const CustomCarousel = ({
  images,
  autoplaySpeed,
  sliderClassName,
  slidesToShow,
  slidesToScroll,
  autoplay,
  arrows,
}) => {
  const settings = {
    slidesToShow: slidesToShow || 1,
    slidesToScroll: slidesToScroll || 1,
    autoplay: autoplay || false,
    autoplaySpeed: autoplaySpeed || 2000,
    arrows: arrows || true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    pauseOnHover: false,
  };

  return (
    <Slider {...settings} className="w-full max-h-[80vh] overflow-hidden">
      {images.map((cur, index) => (
        <div key={index}>
          <img
            src={cur.image}
            alt=""
            className="object-cover w-full max-h-[100vh]"
          />
        </div>
      ))}
    </Slider>
  );
};

export default CustomCarousel;
