import React from 'react';
import weddingImageUrl from '../../../assets/wedding.jpg';
import preWeddingImageUrl from '../../../assets/prewedding.jpg';
import { ROUTES } from '../../../constant/routes';
import { useNavigate } from 'react-router-dom';

const StoriesHomeDetails = () => {
  const navigate = useNavigate();

  const handleClick = (category) => {
    navigate(`${ROUTES.STORIES}?category=${category}`);
  };

  return (
    <div className="container mx-auto px-4 md:px-8  pb-0 sm:pb-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Wedding Image Section */}
        <div className="relative" onClick={() => handleClick('wedding')} style={{cursor:"pointer"}}>
          <img src={weddingImageUrl} style={{borderRadius:'10px'}} alt="Wedding" className="w-full h-full object-cover" />
          <div style={{borderRadius:'10px'}} className="absolute bottom-0 left-0 p-4 bg-black bg-opacity-50 text-white text-base font-medium rounded-br-lg">
            Wedding
          </div>
        </div>
        {/* Pre-Wedding Image Section */}
        <div className="relative" onClick={() => handleClick('pre-wedding')} style={{cursor:"pointer"}}>
          <img src={preWeddingImageUrl} style={{borderRadius:'10px'}} alt="Pre-Wedding" className="w-full h-full object-cover rounded-lg shadow-lg" />
          <div style={{borderRadius:'10px'}} className="absolute bottom-0 left-0 p-4 bg-black bg-opacity-50 text-white text-base font-medium rounded-br-lg">
            Pre-Wedding
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoriesHomeDetails