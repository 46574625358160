import React, { useEffect, useState } from "react";
import { ADMIN_HOME_CROUSEL } from "../../../constant/apiEndPoint";
import { getRequest } from "../../../helper/AxiosClient";
import { hashLoader } from "../../../helper/Utils";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

// Custom hook for in-view animation
const useInViewAnimation = (delay = 0) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.1, // Trigger when 10% of the element is in view
  });

  const animationProps = {
    ref,
    initial: { opacity: 0, y: 50 },
    animate: inView ? { opacity: 1, y: 0 } : {},
    transition: { delay, duration: 0.5 },
  };

  return animationProps;
};

// Functional component to wrap children with in-view animation
const AnimatedDiv = ({ children, delay }) => {
  const animationProps = useInViewAnimation(delay);
  return <motion.div {...animationProps}>{children}</motion.div>;
};

const Testimonials = () => {
  const [getAllDataImage, setGetAllDataImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState({}); // Keep track of loaded images

  const getStoriesList = async () => {
    setLoading(true);
    try {
      const res = await getRequest(`${ADMIN_HOME_CROUSEL}?type=testimonial`);
      setGetAllDataImage(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoriesList();
  }, []);

  const handleImageLoad = (id) => {
    setLoaded((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  return (
    <>
      {loading ? (
        hashLoader()
      ) : (
        <div className="container mx-auto w-full sm:w-[80vw] px-2">
          {getAllDataImage.map((cur, index) => (
            <AnimatedDiv key={cur.id} delay={index * 0.1}>
              <div
                className={`m-[1px] transition-opacity duration-1000 ${
                  loaded[cur.id] ? "opacity-100" : "opacity-0"
                }`}
              >
                <img
                  src={cur.image}
                  alt="testimonial"
                  onLoad={() => handleImageLoad(cur.id)}
                  className="w-full object-cover"
                />
              </div>
            </AnimatedDiv>
          ))}
        </div>
      )}
    </>
  );
};

export default Testimonials;
