import React from "react";
import { useNavigate } from "react-router-dom";
import aboutImg from "../../../assets/store.jpg";

const DetailsStore = () => {
  const navigate = useNavigate();
  const handleStore = () => {
    localStorage.setItem("item", 1);
    navigate("/buy");
  };
  console.log(localStorage.getItem("item"), "getCurrentUser");
  return (
    <div className="container mx-auto px-2 w-full sm:w-[70vw] pb-12 mt-12">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <img
          class="w-full object-cover"
          src={aboutImg}
          alt="Modern building architecture"
        />
        <div className="relative">
          <h1 className="text-3xl md:text-3xl mb-4">
            CÂLIN BY SADHANA STUDIO.
          </h1>
          <div>
            <span> ₹8,999.00 </span>
            <span className="line-through text-gray-400">₹15,000.00</span>
          </div>
          <p className="my-4">Hi Beautiful People.</p>
          <p className="my-4">
            Here is the 1st set of presets i am putting up for sale.
          </p>
          <p className="my-4">Content In Package: 4 Presets.</p>
          <p className="my-4">
            go crazy with your imagination and create some mind blowing imagery.
          </p>
          {localStorage.getItem("item") ? (
            <div class="inline-flex my-11 absolute bottom-0  group">
              <div class="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>
              <button class="relative inline-flex items-center justify-center px-8 py-4 text-sm font-bold text-white transition-all duration-200 bg-gray-900">
                ADDED
              </button>
            </div>
          ) : (
            <div class="inline-flex my-11 absolute bottom-0  group">
              <div class="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>
              <button
                onClick={() => handleStore()}
                class="relative inline-flex items-center justify-center px-8 py-4 text-sm font-bold text-white transition-all duration-200 bg-gray-900"
              >
                ADD TO CART
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsStore;
