import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ALL_STORIES_LIST } from "../../../constant/apiEndPoint";
import { getRequest } from "../../../helper/AxiosClient";
import { hashLoader } from "../../../helper/Utils";
import YouTube from "react-youtube";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

// Custom hook for in-view animation
const useInViewAnimation = (delay = 0) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.1, // Trigger when 10% of the element is in view
  });

  const animationProps = {
    ref,
    initial: { opacity: 0, y: 50 },
    animate: inView ? { opacity: 1, y: 0 } : {},
    transition: { delay, duration: 0.5 },
  };

  return animationProps;
};

// Functional component to wrap children with in-view animation
const AnimatedDiv = ({ children, delay }) => {
  const animationProps = useInViewAnimation(delay);
  return <motion.div {...animationProps}>{children}</motion.div>;
};

const StoriesDetails = () => {
  const [loading, setLoading] = useState(false);
  const [allStoriesList, setAllStoriesList] = useState([]);
  const { id } = useParams();

  const getStoriesList = async () => {
    setLoading(true);
    try {
      const res = await getRequest(`${ALL_STORIES_LIST}?id=${id}`);
      setAllStoriesList(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getStoriesList();
    // eslint-disable-next-line
  }, []);

  const opts = {
    height: "500", // Adjust the height as needed
    width: "100%", // Set width to 100% to fit within the container
    playerVars: {
      autoplay: 0,
    },
  };

  const getYouTubeVideoId = (url) => {
    const regex =
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const matches = url.match(regex);
    return matches ? matches[1] : null;
  };

  return (
    <>
      {loading ? (
        hashLoader()
      ) : (
        <div className="container mx-auto px-4 w-full sm:w-[80vw] font-light">
          <AnimatedDiv delay={0}>
            <div className="relative container mx-auto cursor-pointer">
              <img
                className=" w-full object-cover"
                src={allStoriesList?.coverPhoto?.image}
                alt="Cover"
              />
            </div>
          </AnimatedDiv>
          <AnimatedDiv delay={0.3}>
            <div className="text-center my-4 sm:my-8">
              <h1 className="text-lg sm:text-3xl font-light">
                <strong>
                  {allStoriesList?.maleName} - {allStoriesList?.femaleName}
                </strong>
              </h1>
              <p className="text-sm font-light">
                {moment(allStoriesList?.weddingDate).format("MMMM Do YYYY")}
              </p>
            </div>
          </AnimatedDiv>
          <AnimatedDiv delay={0.5}>
            <div className="text-center mb-4 sm:mb-8 text-xs sm:text-base">
              <p className="mb-2">
                <strong>Destinations :- </strong> {allStoriesList?.destination}
              </p>
              <p className="mb-2">
                <strong>Photographs :- </strong> {allStoriesList?.photographers}
              </p>
              <p className="mb-2">
                <strong>Videographers :- </strong>{" "}
                {allStoriesList?.videographers}
              </p>
              <p className="mb-2">
                <strong>Location :- </strong> {allStoriesList?.locations}
              </p>
              <p className="mb-2">
                <strong>Makeup :- </strong> {allStoriesList?.makeupBy}
              </p>
              <p className="mb-2">
                <strong>Outfits :- </strong> {allStoriesList?.outfitBy}
              </p>
              <p className="mb-2">
                <strong>Event Organiser :- </strong>{" "}
                {allStoriesList?.eventOrganiser}
              </p>
              <p className="mb-2">
                <strong>DJ :- </strong> {allStoriesList?.djBy}
              </p>
              <p className="mb-2">
                <strong>Mehendi By :- </strong> {allStoriesList?.mehendiBy}
              </p>
            </div>
          </AnimatedDiv>
          <div className="grid grid-cols-1 gap-[1px]">
            {allStoriesList?.media?.map((cur, index) => {
              const videoId = getYouTubeVideoId(cur.path);
              return (
                <AnimatedDiv key={index} delay={index * 0.1}>
                  <div style={{ width: "100%", overflow: "hidden" }}>
                    {cur.type === "VIDEO" && videoId ? (
                      <YouTube videoId={videoId} opts={opts} />
                    ) : (
                      <img
                        src={cur.path}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    )}
                  </div>
                </AnimatedDiv>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default StoriesDetails;
