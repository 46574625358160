import axios from "axios";
import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import {
  ADD_IMAGE_TESTIMONIAL,
  ADMIN_HOME_CROUSEL,
  API_URL,
  DELETE_BIG_CAROUSEL,
} from "../../../constant/apiEndPoint";
import { deleteRequest, getRequest } from "../../../helper/AxiosClient";
import { getCurrentUser, hashLoader } from "../../../helper/Utils";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";

const AdminTestimonials = () => {
  const [getAllDataImage, setGetAllDataImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const maxNumber = 69;

  const getStoriesList = async () => {
    setLoading(true);
    try {
      const res = await getRequest(`${ADMIN_HOME_CROUSEL}?type=testimonial`);
      setGetAllDataImage(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoriesList();
  }, []);

  const addBigCarousel = async (imageList) => {
    setLoading(true);
    try {
      const imageURL = imageList[imageList.length - 1].file;
      const formData = new FormData();
      formData.append("image", imageURL);
      const response = await axios.post(
        `${API_URL}${ADD_IMAGE_TESTIMONIAL}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: getCurrentUser()?.token,
          },
        }
      );
      successNotification(response.data.message);
      getStoriesList();
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const onImageRemoveSmall = async (image) => {
    setLoading(true);
    try {
      const res = await deleteRequest(`${DELETE_BIG_CAROUSEL}?id=${image.id}`);
      if (res.type === 1) {
        successNotification(res?.response?.data?.message);
        getStoriesList();
        setLoading(false);
      }
      if (res.type === 2) {
        errorNotification(res.errormessage);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        hashLoader()
      ) : (
        <div className=" px-2 grid container w-[80vw] mx-auto pb-10">
          <ImageUploading
            multiple
            value={getAllDataImage}
            onChange={(imageList) => addBigCarousel(imageList)}
            maxNumber={maxNumber}
            dataURLKey="image"
          >
            {({ imageList, onImageUpload }) => (
              <div class="">
                <div className="px-2 container mx-auto w-[80vw]">
                  <button
                    onClick={onImageUpload}
                    className="border-2 border-red-700 w-full py-8 mb-2"
                  >
                    Click
                  </button>
                </div>
                {imageList.map((image) => {
                  return (
                    <div class="inline-block px-3 relative">
                      <span
                        className="absolute p-2 rounded-lg text-black hover:text-black bg-gray-50 hover:bg-gray-50 opacity-50 cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        onClick={() => onImageRemoveSmall(image)}
                      >
                        Remove
                      </span>
                      <div className="px-2 container w-[80vw] mx-auto">
                        <img src={image.image} alt="" className="mt-[1px]" />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </ImageUploading>
        </div>
      )}
    </>
  );
};

export default AdminTestimonials;
