import React, { useEffect, useState } from "react";
import { ROUTES } from "../../../constant/routes";
import { useNavigate } from "react-router-dom";
import { deleteRequest, getRequest } from "../../../helper/AxiosClient";
import {
  ALL_STORIES_LIST,
  DELETE_STORIES,
} from "../../../constant/apiEndPoint";
import { hashLoader } from "../../../helper/Utils";
import moment from "moment";
import Modal from "./Modal";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";

const AdminStories = () => {
  const navigate = useNavigate();
  const [allStoriesList, setAllStoriesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [itemSelectedId, setItemSelectedId] = useState("");

  const getStoriesList = async () => {
    setLoading(true);
    try {
      const res = await getRequest(ALL_STORIES_LIST);
      setAllStoriesList(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoriesList();
  }, []);

  const closeModal = () => {
    setDeleteModal(false);
  };

  const handleDeleteApiCall = async () => {
    setLoading(true);
    try {
      const res = await deleteRequest(`${DELETE_STORIES}?id=${itemSelectedId}`);
      if (res.type === 1) {
        successNotification(res?.response?.data?.message);
        getStoriesList();
        closeModal();
        setLoading(false);
      }
      if (res.type === 2) {
        errorNotification(res.errormessage);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="w-full flex justify-end mb-2 container mx-auto sm:w-[80vw] px-2">
        <button
          onClick={() => navigate(ROUTES.ADD_ADMIN_STORIES)}
          className="px-4 py-2 text-sm text-center text-white bg-[#da2337] rounded-lg mx-2"
        >
          ADD STORIES
        </button>
      </div>
      {loading ? (
        hashLoader()
      ) : allStoriesList.lenght === 0 ? (
        <h1 className="text-center mt-60">No Data Found</h1>
      ) : (
        <>
          <div className="container mx-auto w-full sm:w-[80vw] px-2 mt-5">
            {allStoriesList.map((cur) => (
              <div className="relative" key={cur.id}>
                <div
                  className="container mx-auto cursor-pointer pb-10 relative z-0"
                  onClick={() => navigate(`edit-stories/${cur.id}`)}
                >
                  <img
                    className="object-cover w-full"
                    src={cur.coverPhoto?.image}
                    alt=""
                  />
                  <figcaption className="mt-2 text-3xl">
                    {cur.maleName} - {cur.femaleName}
                  </figcaption>
                  <figcaption className="mt-2 text-sm">
                  <span>{cur.type}</span> -{" "}
                   {moment(cur.weddingDate).format("MMMM Do YYYY")}  
                  </figcaption>
                </div>
                <div
                  className="absolute z-50 text-fuchsia-800 right-[-10px] top-[-10px] cursor-pointer"
                  onClick={() => {
                    setItemSelectedId(cur.id);
                    setDeleteModal(true);
                  }}
                  style={{
                    background: "white",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                >
                  <img
                    src="https://cdn-icons-png.flaticon.com/128/10124/10124758.png"
                    className="w-6 h-6 "
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>
          <Modal
            isOpen={deleteModal}
            closeModal={closeModal}
            handleDeleteApiCall={handleDeleteApiCall}
            text={"Are you sure delete this story"}
          />
        </>
      )}
    </>
  );
};

export default AdminStories;
