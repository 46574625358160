import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Navbar from "./components/navbar/Navbar";
import { ROUTES } from "./constant/routes";
import RequireAuth from "./helper/RequireAuth";
import { getCurrentUser } from "./helper/Utils";
import AdminEnquire from "./view/adminPenal/AdminEnquire/AdminEnquire";
import Login from "./view/adminPenal/LoginScreen/Login";
import AdminHome from "./view/adminPenal/home/AdminHome";
import AddStories from "./view/adminPenal/stories/AddStories";
import AdminStories from "./view/adminPenal/stories/AdminStories";
import AdminTestimonials from "./view/adminPenal/testimonials/AdminTestimonials";
import About from "./view/publicScreen/about/About";
import Enquire from "./view/publicScreen/enquire/Enquire";
import Home from "./view/publicScreen/home/Home";
import DetailsStore from "./view/publicScreen/store/DetailsStore";
// import Store from "./view/publicScreen/store/Store";
import Stories from "./view/publicScreen/stories/Stories";
import StoriesDetails from "./view/publicScreen/stories/StoriesDetails";
import Testimonials from "./view/publicScreen/testimonials/Testimonials";
import Cart from "./view/publicScreen/store/Cart";
import Footer from "./components/navbar/Footer";
import StoriesHomeDetails from "./view/publicScreen/stories/StoriesHomeDetails";

const App = () => {
  const location = useLocation().pathname;
  const token = getCurrentUser()?.token;
  const navigate = useNavigate();
  const publicRoute = [
    {
      to: ROUTES.LOGIN_ROUTE,
      Component: Login,
    },
    {
      to: ROUTES.BUY,
      Component: Cart,
    },
    {
      to: ROUTES.HOME,
      Component: Home,
    },
    {
      to: ROUTES.STORIES_HOME,
      Component: StoriesHomeDetails,
    },
    {
      to: `${ROUTES.STORIES_HOME}/${ROUTES.STORIES}`,
      Component: Stories,
    },
    {
      to:`${ROUTES.STORIES_DETAILS}` ,
      Component: StoriesDetails,
    },
    {
      to: ROUTES.TESIMONIALS,
      Component: Testimonials,
    },
    // {
    //   to: ROUTES.STORE,
    //   Component: Store,
    // },
    {
      to: ROUTES.DETAILS_STORE,
      Component: DetailsStore,
    },
    {
      to: ROUTES.ABOUT,
      Component: About,
    },
    {
      to: ROUTES.ENQURIE,
      Component: Enquire,
    },
  ];

  const adminRoute = [
    {
      to: ROUTES.ADMIN_HOME,
      Component: AdminHome,
      allowedRoles: ["admin"],
    },
    {
      to: ROUTES.ADMIN_STORIES,
      Component: AdminStories,
      allowedRoles: ["admin"],
    },
    {
      to: ROUTES.ADD_ADMIN_STORIES,
      Component: AddStories,
      allowedRoles: ["admin"],
    },
    {
      to: ROUTES.EDIT_ADMIN_STORIES,
      Component: AddStories,
      allowedRoles: ["admin"],
    },
    {
      to: ROUTES.TESTIMONIAL,
      Component: AdminTestimonials,
      allowedRoles: ["admin"],
    },
    {
      to: ROUTES.ADMIN_ENQURIRE,
      Component: AdminEnquire,
      allowedRoles: ["admin"],
    },
  ];

  return (
    <>
      {localStorage.getItem("item") && (
        <div
          onClick={() => navigate(ROUTES.BUY)}
          className="p-3 cursor-pointer rounded-[30px] absolute z-30 right-5 flex justify-between top-3 w-[220px] bg-black text-white"
        >
          <div className="flex">
            <img
              src="https://assets.squarespace.com/universal/images-v6/damask/cart-16-light.png"
              alt="cart"
            />
            <span className="px-2">{localStorage.getItem("item")} Item</span>
          </div>
          <div>₹8,999.00</div>
        </div>
      )}
      <div className="bg-[#f5f1eb] font-[200] relative">
        <ToastContainer />
        {location !== "/login" && <Navbar />}
        <Routes>
          <Route>
            {publicRoute.map(({ to, Component }) => {
              return (
                <Route key={to}>
                  <Route element={<Component />} />
                  <Route path={to} element={<Component />} />
                </Route>
              );
            })}
          </Route>
          <Route
            path="/"
            element={<Navigate to={token ? ROUTES.ADMIN_HOME : ROUTES.HOME} />}
          />
          <Route>
            {adminRoute.map(({ allowedRoles, to, Component }) => {
              return (
                <Route
                  key={to}
                  element={<RequireAuth allowedRoles={allowedRoles} />}
                >
                  <Route path={to} element={<Component />} />
                </Route>
              );
            })}
          </Route>
        </Routes>
        {location !== "/login" && <Footer />}
      </div>
    </>
  );
};

export default App;
